#login,
#loggedin,
#search-pagination,
#step-2,
#playlist-button {
  display: none;
}

html,
body {
  background-color: #212121;
  color: #1db954;
}

input {
  margin-bottom: 1%;
}

.selected-track::before {
  content: "Selected";
}

.selected-track {
  box-shadow: 0 0 0.8rem 0.1rem green !important;
  transform: scale(1.1);
  z-index: 999;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 500px;
}

.login-btn {
  background-color: #1db954;
  border-color: #191414;
}

.login-btn:hover {
  background-color: white;
  color: #1db954;
  border-color: #1db954;
}

.login-btn:active {
  background-color: #1db954 !important;
  border-color: #191414 !important;
  color: white !important;
}

.login-btn:focus {
  box-shadow: 0 0 0 0.2rem #1db954;
}

.spotify-green-bg {
  background-color: #1db954;
}

.spotify-black-border {
  border-color: #191414;
}

.card {
  background-color: #1db954;
  color: white;
}

.card:hover,
.card:focus {
  background-color: #218838;
  border-color: #1e7e34;
  cursor: pointer;
}

.search-results {
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 800px) {
  .col-sm-3 {
    display: inline-block;
    text-align: center;
    padding-bottom: 0.6vw;
  }
}

#exp-filter {
  display: none;
}

@media only screen and (min-width: 800px) {
  .col-sm-3 {
    display: inline-block;
    text-align: center;
    padding-bottom: 0.6vw;
  }
}

@media only screen and (min-width: 575px) and (max-width: 800px) {
  .col-sm-3 {
    display: inline-block;
    max-width: 40%;
  }
}

body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
}

#additional-options {
  display: none;
}
